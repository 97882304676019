import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {getBlLocaleFromString} from "lib/BlLocale";
import {BundleEntry} from "components/bundle-entry/BundleEntry";
import FavoritesInclusiveLastOrders from "./components/favorites-inclusive-last-orders/FavoritesInclusiveLastOrders";

const target = document.getElementById('last-orders-and-favorites');
const rawLocaleCode = target?.dataset?.['localeCode'];
const localeCode = getBlLocaleFromString(rawLocaleCode);
const isAnonymous = target?.dataset?.['isAnonymous'];
const isApp = target?.dataset?.['isApp'];

if(target) {
    ReactDOM.render(
        <BundleEntry
            bundleName="last-orders-and-favorites"
            locale={localeCode}
            prefetchTranslations={[
                "frontend.topnavbarmenu.favorites",
                "frontend.topnavbarmenu.favoritesInclusiveLastOrders",
                "frontend.favorites.empty.title",
                "frontend.favorites.empty.line1",
                "frontend.favorites.empty.line2",
                'frontend.favorites.add',
                'frontend.favorites.remove',
                "frontend.topnavbarmenu.my.articles",
                "frontend.topnavbarmenu.articles.rebuy",
                "frontend.topnavbarmenu.favorites",
                "frontend.advanced-search.results.noHits",
                "frontend.banner.defaultTitle",
                "frontend.banner.defaultSubTitle"
            ]}
        >
            <FavoritesInclusiveLastOrders
                blackFridayActive={false}
                isApp={isApp === 'true'}
                hideBanner={true}
                isAnonymous={isAnonymous === 'true'}/>
        </BundleEntry>,
        target
    );
}
